export const environment = {
  production: false,
  idp: 'https://testidp.unomain.com',
  clientId: 'member-apps',
  vendors_api: 'https://testapi.unomain.com/shop/api',
  prime_api: 'https://testapi.unomain.com/prime/api',
  oms_api: 'https://testomsapi.unomain.com',
  imageApiUrl: 'https://images/files/upload',
  memberSiteBase: 'https://dev.shelfspace.shop',
  packages_url: 'https://dev-packages.unomain.com',
};
