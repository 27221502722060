<mat-toolbar *ngIf="!isUserLoggedIn" color="black" class="navbar h-auto text-light bg-dark flex-wrap">
	<div class="container p-0 d-flex justify-content-between">
		<div class="align-self-start">
			<!--a [routerLink]="['/']" class="d-block"><img src="assets/images/premier-logo.png" style="height:4em; position: relative;" /></a-->
		</div>
		<div *ngIf="!isUserLoggedIn">
			<button mat-icon-button routerLink="/users/login">
				<mat-icon style="color: white;">person</mat-icon>
			</button>
		</div>
		<div *ngIf="isUserLoggedIn">
			<button mat-icon-button (click)="toggleMenu()">
				<mat-icon style="color: white;">menu</mat-icon>
			</button>
		</div>
	</div>
</mat-toolbar>
<div class="page__wrapper chiller-theme toggled">
	<div class="openButton__wrapper" *ngIf="isUserLoggedIn">
		<a (click)="toggleMenu()" class="openButton" href="javascript: void(0);">
			<span class="openButton__hamburger"></span>
		</a>
	</div>

	<nav *ngIf="isUserLoggedIn" id="sidebar"
		[ngClass]="{'sidebar__wrapper': true, 'sidebar__wrapper--toggled': isUserLoggedIn && showMenu}">
		<div class="sidebar__content">
			<!-- sidebar-logo  -->
			<div class="brand">
				<a [routerLink]="['/']" class="brand__logo"><img src="assets/images/premier-logo.png"
						style="height:2em; position: relative;" /></a>
				<button class="closeButton" (click)="toggleMenu()">
					<span class="closeButton__hamburger"></span>
				</button>
			</div>
			<!-- sidebar-logo /end -->

			<!-- sidebar-header  -->
			<div class="sidebarHeader">
				<div class="userInfo">
					<div class="userInfo__username">
						{{ getUsername() }}
					</div>
					<span class="userInfo__status">
						<i class="userInfo__statusIcon material-icons md-15">account_circle</i>
						<span class="userInfo__statusText">Online</span>
					</span>
				</div>
			</div>
			<!-- sidebar-header /end  -->

			<!-- sidebar-search  -->
			<div *ngIf="isVisible" class="sidebar-search">
				<div>
					<div class="input-group">
						<input type="text" class="form-control search-menu" placeholder="Search..." />
						<div class="input-group-append">
							<span class="input-group-text">
								<i class="material-icons">search</i>
							</span>
						</div>
					</div>
				</div>
			</div>
			<!-- sidebar-search /end -->

			<!-- sidebar-menu  -->
			<div class="mainMenu">
				<ul class="mainMenu__list">
					<li class="mainMenu__header">
						<span class="mainMenu__headerText">General</span>
					</li>

					<!-- Dashboard -->
					<li class="mainMenu__menuItem">
						<a class="mainMenu__menuLink" (click)="toggleMenu()" routerLink="/">
							<i class="mainMenu__menuIcon material-icons">dashboard</i>
							<span class="mainMenu__menuText">Dashboard</span>
						</a>
					</li>
					<!-- END Dashboard -->

					<!-- E-commerce -->
					<li class="mainMenu__menuItem mainMenu__menuItem--dropdown">
						<a class="mainMenu__menuLink mainMenu__menuLink--dropdown collapsed" href="#"
							data-toggle="collapse" data-target="#ecommerce">
							<i class="mainMenu__menuIcon material-icons">shopping_cart</i>
							<span class="mainMenu__menuText">E-commerce</span>
						</a>
						<div id="ecommerce" class="submenu__wrapper submenu--collapse collapse">
							<ul class="submenu">
								<li class="submenu__item">
									<a class="submenu__link mainMenu__menuLink mainMenu__menuLink--dropdown collapsed" href="#!" data-toggle="collapse"
										data-target="#orderedProductsSubMenu">
										Ordered Products
									</a>
									<div id="orderedProductsSubMenu"
										class="submenu__wrapper submenu--collapse collapse">
										<ul class="submenu">
											<li class="submenu__item">
												<a (click)="toggleMenu()" routerLink="/orders" class="submenu__link">
													Shelfspace
												</a>
											</li>
										</ul>
									</div>
								</li>
								<li class="submenu__item">
									<a class="submenu__link " (click)="toggleMenu()" routerLink="/themes/edit">
										Shop Theme
									</a>
								</li>
								<li class="submenu__item">
									<a class="submenu__link " (click)="toggleMenu()" routerLink="/products/pricing">
										Product Prices
									</a>
								</li>
							</ul>
						</div>
					</li>
					<!-- END E-commerce -->

					<!-- Packages -->
					<li class="mainMenu__menuItem mainMenu__menuItem--dropdown">
						<a class="mainMenu__menuLink mainMenu__menuLink--dropdown collapsed" href="#"
							data-toggle="collapse" data-target="#packages">
							<i class="mainMenu__menuIcon material-icons">all_inbox</i>
							<span class="mainMenu__menuText">Packages</span>
						</a>
						<div id="packages" class="submenu__wrapper submenu--collapse collapse">
							<ul class="submenu">
								<!-- <li class="submenu__item">
									<a class="submenu__link" (click)="clickPackages()" target="_blank" [attr.href]="packagesUrl">
										Buy Packages
									</a>
								</li>
								<li class="submenu__item">
									<a class="submenu__link " (click)="toggleMenu()" routerLink="/orders/packages/list">
										Ordered Packages
									</a>
								</li> -->
								<li class="submenu__item">
									<a class="submenu__link " (click)="toggleMenu()" routerLink="/packages/pins">
										Pins
									</a>
								</li>
							</ul>
						</div>
					</li>
					<!-- END Packages -->

					<li *ngIf="isVisible" class="mainMenu__menuItem mainMenu__menuItem--dropdown">
						<a class="mainMenu__menuLink mainMenu__menuLink--dropdown collapsed" href="#"
							data-toggle="collapse" data-target="#components">
							<i class="mainMenu__menuIcon material-icons">extension</i>
							<span class="mainMenu__menuText">Components</span>
						</a>
						<div id="components" class="submenu__wrapper submenu--collapse collapse">
							<ul class="submenu">
								<li class="submenu__item">
									<a class="submenu__link" href="#">General</a>
								</li>
								<li class="submenu__item">
									<a class="submenu__link" href="#">Panels</a>
								</li>
								<li class="submenu__item">
									<a class="submenu__link" href="#">Tables</a>
								</li>
								<li class="submenu__item">
									<a class="submenu__link" href="#">Icons</a>
								</li>
								<li class="submenu__item">
									<a class="submenu__link" href="#">Forms</a>
								</li>
							</ul>
						</div>
					</li>
					<li *ngIf="isVisible" class="sidebar-dropdown">
						<a href="#" data-toggle="collapse" data-target="#charts">
							<i class="material-icons">insert_chart</i>
							<span>Charts</span>
						</a>
						<div id="charts" class="sidebar-submenu collapse">
							<ul>
								<li>
									<a href="#">Pie chart</a>
								</li>
								<li>
									<a href="#">Line chart</a>
								</li>
								<li>
									<a href="#">Bar chart</a>
								</li>
								<li>
									<a href="#">Histogram</a>
								</li>
							</ul>
						</div>
					</li>
					<li *ngIf="isVisible" class="sidebar-dropdown">
						<a href="#" data-toggle="collapse" data-target="#maps">
							<i class="material-icons">map</i>
							<span>Maps</span>
						</a>
						<div id="maps" class="sidebar-submenu collapse">
							<ul>
								<li>
									<a href="#">Google maps</a>
								</li>
								<li>
									<a href="#">Open street map</a>
								</li>
							</ul>
						</div>
					</li>
					<li *ngIf="isVisible" class="header-menu">
						<span>Extra</span>
					</li>
					<li *ngIf="isVisible">
						<a href="#">
							<i class="material-icons">menu_book</i>
							<span>Documentation</span>
							<span class="badge badge-pill badge-primary">Beta</span>
						</a>
					</li>
					<li *ngIf="isVisible">
						<a href="#">
							<i class="material-icons">calendar_today</i>
							<span>Calendar</span>
						</a>
					</li>
					<li *ngIf="isVisible">
						<a href="#">
							<i class="material-icons">folder</i>
							<span>Examples</span>
						</a>
					</li>
				</ul>
			</div>
			<!-- sidebar-menu /end -->

		</div>
		<!-- sidebar-content  -->
		<div class="menuFooter">
			<a *ngIf="isVisible" class="menuFooter__link" href="#">
				<i class="menuFooter__linkIcon material-icons">notifications</i>
				<span class="badge badge-pill badge-warning notification">3</span>
			</a>
			<a *ngIf="isVisible" class="menuFooter__link" href="#">
				<i class="menuFooter__linkIcon material-icons">email</i>
				<span class="badge badge-pill badge-success notification">7</span>
			</a>
			<a *ngIf="isVisible" href="#">
				<i class="material-icons">settings_applications</i>
				<span class="badge-sonar"></span>
			</a>
			<a class="menuFooter__link" href="javascript: void(0);" (click)="logout()" title="Log Out">
				<i class="menuFooter__linkIcon material-icons">power_settings_new</i>
			</a>
		</div>
	</nav>
	<!-- sidebar-wrapper  -->
	<main [ngClass]="{'page-content': true, 'show-menu': isUserLoggedIn && showMenu}">
		<router-outlet></router-outlet>
	</main>
	<!-- page-content" -->
</div>