import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { FileService } from '../../shared/file.service';
import { HttpEventType } from '@angular/common/http';

@Component({
  selector: 'md-image-upload-component',
  templateUrl: './image-upload-component.component.html',
  styleUrls: ['./image-upload-component.component.scss'],
})
export class ImageUploadComponentComponent implements OnInit, OnChanges {
  public errorMessage = '';
  @Input() public file: File;
  public imageUrl = '';
  @Input() public isUpload: boolean;
  @Output() uploadSuccess = new EventEmitter();
  @Output() uploadError = new EventEmitter();
  public isUploaded = false;
  public isUploading = false;
  public percentCompleted = 0;
  public url: string;

  constructor(private fileService: FileService) {}

  public ngOnChanges(changes: SimpleChanges): void {
    console.log('isUpload: ', this.isUpload);
    if (this.isUpload) {
      const formData = new FormData();
      formData.append('files', this.file);
      this.fileService.uploadFile(formData).subscribe(
        (event) => {
          switch (event.type) {
            case HttpEventType.Sent:
              this.isUploading = true;
              this.percentCompleted = 0;
              this.isUploaded = false;
              break;

            case HttpEventType.UploadProgress:
              this.percentCompleted = Math.round((100 * event.loaded) / event.total);
              if (Math.round((100 * event.loaded) / event.total) === 100) {
                this.percentCompleted = 99;
              }
              break;

            case HttpEventType.Response:
              this.isUploaded = true;
              this.percentCompleted = 100;
              this.isUploading = false;
              this.imageUrl = event.body[0].server_path;
              this.uploadSuccess.emit(event.body[0].server_path);
              break;
            case HttpEventType.DownloadProgress:
              break;
            default:
              console.log(event);
              break;
          }
        },
        (err) => {
          console.error('error: ', err);
          this.errorMessage = err.error.message;
          this.isUploaded = false;
          this.percentCompleted = 0;
          this.isUploading = false;
          this.imageUrl = '';
          this.uploadError.emit(this.errorMessage);
        },
      );
    }
  }

  public ngOnInit(): void {
    this.url = '';
    console.log(this.file);
    if (this.file) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.url = e.target.result;
      };
      reader.readAsDataURL(this.file);
    }
  }
}
