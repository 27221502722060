<div class="container">
  <div class="row vh-80 justify-content-center align-items-center">
    <div class="col-4">
      <mat-card>
        <mat-card-title>Ooops</mat-card-title>
        <mat-card-content>
          <p>The page you're looking for cannot be found.</p>
          <p>Click <a routerLink="/">here</a> to back home.</p>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>