import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map, tap, switchMap, first } from 'rxjs/operators';
import { Vendor } from 'src/app/shared/vendor';
import { throwError, of } from 'rxjs';

const USER_ID_KEY = 'userId';
const USER_NAME_KEY = 'username';
const TOKEN_KEY = 'token';
const TOKEN_EXPIRY_KEY = 'expires_in';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private http: HttpClient) {}

  public formatLoginParams(username: string, password: string) {
    return `username=${username}&password=${password}&grant_type=password&client_id=${environment.clientId}`;
  }

  public getTokenExpiryDate(): any {
    if (this.isLoggedIn()) {
      return new Date(localStorage.getItem(TOKEN_EXPIRY_KEY));
    }
    return new Date(Date.now());
  }

  public getUserDetails(username: string) {
    const link = `${environment.vendors_api}/vendor/${username}`;
    const token = localStorage.getItem(TOKEN_KEY);
    const headers = { Authorization: `Bearer ${token}` };
    return this.http.get<Vendor>(link, { headers });
  }

  public getUserId() {
    if (this.isLoggedIn()) {
      return localStorage.getItem(USER_ID_KEY);
    }
    throw new Error('User must be logged in.');
  }

  public getUsername() {
    if (this.isLoggedIn()) {
      return localStorage.getItem(USER_NAME_KEY);
    }
    throw new Error('User must be logged in.');
  }

  public isLoggedIn() {
    if (localStorage.getItem(TOKEN_KEY)) {
      return true;
    }
    return false;
  }

  public getAccessToken() {
    if (this.isLoggedIn()) {
      const link = `${environment.idp}/api/access`;
      const token = localStorage.getItem(TOKEN_KEY);
      const headers = { Authorization: `Bearer ${token}` };
      this.http
        .post(link, null, { headers })
        .pipe(first())
        .subscribe(
          (resp: string) => {
            localStorage.setItem('access_token', resp);
          },
          (err) => {
            console.error(err);
          },
        );
    }
  }

  public login(username: string, password: string) {
    const link = `${environment.idp}/oauth/token`;

    return this.http.post(link, this.formatLoginParams(username, password)).pipe(
      switchMap((resp: any) => {
        if (!this.isInteger(username)) {
          return this.getUserDetails(username).pipe(
            tap((vendor: Vendor) => {
              localStorage.setItem(USER_ID_KEY, String(vendor.Id));
            }),
            map((value) => {
              return resp;
            }),
          );
        }
        return of(resp);
      }),
      tap((resp) => {
        const jwtPayload = JSON.parse(atob(resp.access_token.split('.')[1]));
        const date = new Date(jwtPayload.exp * 1000);
        if (!localStorage.getItem(USER_ID_KEY)) {
          localStorage.setItem(USER_ID_KEY, username);
        }

        localStorage.setItem(TOKEN_KEY, resp.access_token);
        localStorage.setItem(TOKEN_EXPIRY_KEY, date.toLocaleString());
        localStorage.setItem(USER_NAME_KEY, username);
      }),
    );
  }

  public logout() {
    localStorage.removeItem(USER_ID_KEY);
    localStorage.removeItem(USER_NAME_KEY);
    localStorage.removeItem(TOKEN_KEY);
    localStorage.removeItem(TOKEN_EXPIRY_KEY);
  }

  public saveVendorConfig(config: Vendor) {
    const userId = localStorage.getItem(USER_ID_KEY);
    const link = `${environment.vendors_api}/vendor/${userId}/config`;
    const token = localStorage.getItem(TOKEN_KEY);
    const headers = { Authorization: `Bearer ${token}` };
    return this.http.put<Vendor>(link, config, { headers });
  }

  private isInteger(str: string): boolean {
    return !isNaN(parseInt(str, 10));
  }
}
