import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class IfLoggedInGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const isExpired = this.authService.getTokenExpiryDate() - Date.now() <= 0;
    if (this.authService.isLoggedIn()) {
      if (isExpired) {
        this.authService.logout();
        this.router.navigate(['/users/login'], { queryParams: { message: 'Session expired. Please log in.' } });
        return false;
      } else {
        return true;
      }
    }

    this.router.navigate(['/users/login']);
    return false;
  }
}
