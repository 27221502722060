<div class="container">
  <div class="row vh-100 justify-content-center align-items-center">
    <div class="col-md-4">
      <mat-card>
        <mat-card-title>Welcome</mat-card-title>
        <mat-card-content>
          <p>
            Welcome to the member's dashboard. To see the orders
            go to the menu on the left side bar and click on "E-commerce" then "<a routerLink="/orders">Ordered Products</a>".
          </p>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>