import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FileService } from '../../shared/file.service';
import { FormControl } from '@angular/forms';
import { Vendor } from '../../shared/vendor';
import { AuthService } from '../../shared/auth.service';

@Component({
  selector: 'md-multiple-image-upload-dialog',
  templateUrl: './multiple-image-upload-dialog.component.html',
  styleUrls: ['./multiple-image-upload-dialog.component.scss'],
})
export class MultipleImageUploadDialogComponent implements OnInit {
  public doneCtr = 0;
  public file = new FormControl('');
  public files: File[] = [];
  public imageServerUrls: string[] = [];
  public startUploading = false;
  public urls: string[] = [];
  public errorMessage: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: Vendor,
    private fileService: FileService,
    private authService: AuthService,
    public dialogRef: MatDialogRef<MultipleImageUploadDialogComponent>,
  ) {}

  public close() {}

  public save() {
    this.data.Config.EnableSlider = true;
    this.data.Config.Slides = this.imageServerUrls;
    this.authService.saveVendorConfig(this.data).subscribe(
      (resp) => {
        this.dialogRef.close(this.imageServerUrls);
      },
      (error) => {
        console.error(error);
      },
    );
  }

  public ngOnInit(): void {
    console.log(this.data);
  }

  public onFileChange(files: File[]) {
    this.doneCtr = 0;
    this.startUploading = false;
    this.urls = [];
    this.files = [];
    console.log(files);
    if (files) {
      this.files = files;
      for (const file of files) {
        const reader = new FileReader();
        reader.onload = (e: any) => {
          this.urls.push(e.target.result);
        };
        reader.readAsDataURL(file);
      }
    }
  }

  public startUpload() {
    this.startUploading = true;
  }

  public uploadError(message: string, index: number) {
    console.log(message, index);
    this.doneCtr++;
    if (this.doneCtr === this.files.length) {
      this.startUploading = false;
    }
  }

  public uploadSuccess(url: string, index: number) {
    console.log(url, index);
    this.imageServerUrls.push(url);
    this.doneCtr++;
    if (this.doneCtr === this.files.length) {
      this.startUploading = false;
    }
  }
}
