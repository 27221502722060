import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainNavComponent } from './main-nav/main-nav.component';
import { SharedModule } from '../shared/shared.module';
import { HomePageComponent } from './home-page/home-page.component';
import { NotFoundPageComponent } from './not-found-page/not-found-page.component';

@NgModule({
  declarations: [MainNavComponent, HomePageComponent, NotFoundPageComponent],
  imports: [CommonModule, SharedModule],
  exports: [MainNavComponent]
})
export class MainModule {}
