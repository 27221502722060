import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { IfLoggedInGuard } from './shared/if-logged-in.guard';
import { HomePageComponent } from './main/home-page/home-page.component';
import { NotFoundPageComponent } from './main/not-found-page/not-found-page.component';

const routes: Routes = [
  {
    path: 'orders',
    loadChildren: () => import('./orders/orders.module').then((m) => m.OrdersModule),
  },
  {
    path: 'users',
    loadChildren: () => import('./users/users.module').then((m) => m.UsersModule),
  },
  {
    path: 'themes',
    loadChildren: () => import('./theme/theme.module').then((m) => m.ThemeModule),
  },
  {
    path: 'products',
    loadChildren: () => import('./products/products.module').then((m) => m.ProductsModule),
  },
  {
    path: 'packages',
    loadChildren: () => import('./packages/packages.module').then((m) => m.PackagesModule),
  },
  {
    path: '',
    canActivate: [IfLoggedInGuard],
    component: HomePageComponent,
  },
  { path: '**', component: NotFoundPageComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
