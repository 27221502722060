import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';

import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { Router } from '@angular/router';

/** Pass untouched request through to the next request handler. */
@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService, private router: Router) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const isExpired = this.authService.getTokenExpiryDate() - Date.now() <= 0;
    if (this.authService.isLoggedIn() && isExpired) {
      this.authService.logout();
      this.router.navigate(['/users/login'], { queryParams: { message: 'Log in token has expired. You need to log in again.' } });
    }
    return next.handle(req);
  }
}
