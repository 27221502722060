<div class="imageGallery__imageContainer">
  <img class="imageGallery__image" [src]="url" />
  <div class="progress imageGallery__progressContainer" *ngIf="isUploading">
    <div class="progress-bar imageGallery__progress" role="progressbar" [ngStyle]="{'width': percentCompleted + '%'}" [attr.aria-valuenow]="percentCompleted"
      aria-valuemin="0" aria-valuemax="100">{{percentCompleted}}%</div>
  </div>
  <div class="imageGallery__iconContainer" *ngIf="errorMessage" [title]="errorMessage">
    <mat-icon class="imageGallery__icon imageGallery__icon-error">error</mat-icon>
  </div>
  <div class="imageGallery__iconContainer" *ngIf="percentCompleted == 100" title="Upload complete">
    <mat-icon class="imageGallery__icon imageGallery__icon-success">check_circle</mat-icon>
  </div>
</div>