import { environment } from './../../../environments/environment';
import { Component, OnInit, DoCheck, OnDestroy, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable, from, fromEvent } from 'rxjs';
import { map, filter, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { AuthService } from '../../shared/auth.service';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { BlockingProxy } from 'blocking-proxy';

const FULL_WIDTH = '100%';
const NO_WIDTH = '0%';

@Component({
  selector: 'md-main-nav',
  templateUrl: './main-nav.component.html',
  styleUrls: ['./main-nav.component.scss'],
  animations: [
    trigger('openClose', [
      state(
        'open',
        style({
          width: '260px',
          opacity: 1,
        }),
      ),
      state(
        'closed',
        style({
          width: '0px',

          opacity: 0,
        }),
      ),
      transition('open => closed', [animate('0.3s')]),
      transition('closed => open', [animate('0.3s')]),
    ]),
  ],
})
export class MainNavComponent implements OnInit, DoCheck, OnDestroy, AfterViewInit {
  private cartServiceSubscription: any;
  private cartSubscription: any;
  private itemCount: number;

  public isUserLoggedIn: boolean;
  public isVisible = false;
  public search = '';
  @ViewChild('searchInput')
  public searchInputElement: ElementRef;
  public showMenu = true;
  public user: any;
  public packagesUrl = '';

  constructor(private router: Router, private authService: AuthService) {}

  public clearSearch() {
    this.search = '';
    this.onSubmit();
  }

  public getUsername() {
    return this.authService.getUsername();
  }

  public goToLogin() {
    this.router.navigate(['/users/login']);
  }

  public logout() {
    this.authService.logout();
    this.goToLogin();
  }

  public ngAfterViewInit(): void {}

  public ngDoCheck() {
    this.isUserLoggedIn = this.authService.isLoggedIn();
    if (this.isUserLoggedIn) {
      this.packagesUrl = `${environment.packages_url}/${this.authService.getUsername()}/access/${localStorage.getItem('access_token')}`;
    }
  }

  public ngOnDestroy() {
    this.cartServiceSubscription.unsubscribe();
    this.cartSubscription.unsubscribe();
  }

  public ngOnInit(): void {
    this.isUserLoggedIn = false;
  }

  public onSubmit() {
    if (this.search) {
      this.router.navigate(['/products'], {
        queryParams: { search: this.search },
      });
    } else {
      this.router.navigate(['/products']);
    }
  }

  public toggleMenu() {
    this.showMenu = !this.showMenu;
  }

  public clickPackages() {
    this.authService.getAccessToken();
  }
}
