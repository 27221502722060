<h2 mat-dialog-title>Upload Images</h2>
<mat-dialog-content class="mat-typography">
  <div class="alert alert-danger" *ngIf="errorMessage" role="alert">
    {{errorMessage}}
  </div>
  <div class="uploadFile__container">
    <button class="uploadFile__button">
      <span class="material-icons">add_a_photo</span>
    </button>
    <input type="file" class="uploadFile__input" [formControl]="file" (change)="onFileChange($event.target.files)" id="customFile" multiple />
  </div>

  <div class="imageGallery__container">
    <md-image-upload-component *ngFor="let file of files; index as i;" [file]="file" [isUpload]="startUploading" (uploadSuccess)="uploadSuccess($event, i)"
      (uploadError)="uploadError($event, i)">
    </md-image-upload-component>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cancel</button>
  <button mat-button (click)="startUpload()" [disabled]="!(urls.length > 0 && !startUploading)">Upload</button>
  <button mat-button (click)="save()" [disabled]="doneCtr < files.length" cdkFocusInitial>Ok</button>
</mat-dialog-actions>